.icon {
  width: 30px;
  height: 30px;
}
.sidebar {
    background-color: #2E8BFA;
  }
  .navCheckbox {
    display: none;
  }
  .navCheckboxLabel {
    cursor: pointer;
    position: absolute;
    right: 20px;
    margin: 10px;
  }
  .navCheckboxLabel img {
    width: 30px;
    height: 30px;
  }
  .navCheckboxLabel .close {
    display: none;
  }
  .navCheckboxLabel .close,
  .navCheckboxLabel.menu {
    margin-top: 3px;
  }
  .navMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px;
    list-style: none;
  }
  .navMenu li {
    display: none;
    cursor: pointer;
    color: #FFF;
  }
  .navMenu li:hover {
    text-decoration: underline;
  }
  .navMenu li p{
    color: #FFF;
  }
  .navMenu li:first-child {
    display: block;
    margin-right: auto;
  }
  .navCheckbox:checked ~ .navMenu li {
    display: block;
  }
  .navCheckbox:checked ~ .navCheckboxLabel .close {
    display: block;
  }
  .navCheckbox:checked ~ .navCheckboxLabel .menu {
    display: none;
  }
  
  @media (min-width: 560px) {
    .navCheckboxLabel {
      display: none;
    }
    .navMenu {
      flex-direction: row;
    }
    .navMenu li {
      display: block;
      margin-right: 20px;
    }
  }

  .sinidName {
    color: #2a3587;
}

.logoUsersSize {
    width: 140px;
    height: 50px;
}
.iconoTh {
    width: 60px;
    height: 40px;
}
.thBoton {
    color: white;
    border: 1px solid black;
    border-radius: 7px;
    background-color: #2a3587;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.75);
}