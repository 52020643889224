.dropdownContainer {
    min-height: 20px;
    max-height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #f8f9fa;
  }

.dropdownContainer::-webkit-scrollbar {
  width: 3px;
}

.dropdownContainer::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 5px;
}

.tbody {
  font-size: 13px;
}