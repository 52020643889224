.tbody {
  font-size: 13px;
}

.dropdownContainer {
  min-height: 40px;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f8f9fa;
}

.dropdownContainer::-webkit-scrollbar {
width: 3px;
}

.dropdownContainer::-webkit-scrollbar-thumb {
background-color: white;
border-radius: 5px;
}