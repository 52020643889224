.backgroundImage {
    background-image: url(../assets/fondoLogin.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
}
.sinidHeader {
    font-size: 50px;
    font-weight: 700;
}
.sinidPar {
    font-size: 15px;
}
.ingresoColor {
    font-weight: 700;
    color: #2a3587;
}
.enviarBoton {
    color: white;
    font-size: 17px;
    border: 0px;
    border-radius: 15px;
    background-color: #2a3587;
}